import React from 'react';
import PropTypes from 'prop-types';

import { HTML } from '../../components/utils';
import Submenu from '../../components/submenu';
import useOffers from '../../hooks/use-offers';

import styles from './teaser.module.scss';

const Teaser = ({ content }) => {
	const items = useOffers().map(({ slug, name, navigation }) => ({
		...navigation,
		...{
			slug,
			name
		}
	}));

	return (
		<div className={styles.wrapper}>
			<div className={styles.submenu}>
				<Submenu items={items} />
			</div>
			<div className={styles.header}>
				<HTML content={content} />
			</div>
		</div>
	);
};

Teaser.defaultProps = {
	content: null
};

Teaser.propTypes = {
	content: PropTypes.string.isRequired
};

export default Teaser;
