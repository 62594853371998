import React, { Fragment, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

import Contact from './contact';
import { CloseIcon } from './icons';

import styles from './modal.module.scss';

const Modal = ({ children, title, onClose, showHeader, showFooter }) => {
  useEffect(() => {
    const handleKeyDown = ({ keyCode }) => {
      switch (keyCode) {
        case 27:
          onClose();
          break;
        default:
          break;
      }
    };

    window.addEventListener('keydown', handleKeyDown, false);

    return () => {
      window.removeEventListener('keydown', handleKeyDown, false);
    };
  }, []);

  return (
    <Fragment>
      <Helmet>
        <body className={styles.bodyModalOpen} />
      </Helmet>
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <div className={styles.holder}>
            <div className={styles.header}>
              <h1 className={styles.title}>{title}</h1>
              <button onClick={onClose} className={styles.close}>
                <CloseIcon className={styles.closeSvg} />
              </button>
            </div>
            <div className={styles.content}>{children}</div>
            <div className={styles.footer}>
              <Contact withContainer={false} />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

Modal.defaultProps = {
  title: null,
  onClose: () => {}
};

Modal.propTypes = {
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired
};

export default Modal;
