import React from 'react';
import PropTypes from 'prop-types';

import { SVG } from '../../components/icons';

import styles from './icons.module.scss';

const Icons = ({ icons }) =>
	(Array.isArray(icons) && (
		<div className={styles.wrapper}>
			{icons.map(({ icon: { publicURL: src }, text }, index) => (
				<div key={index} className={styles.item}>
					<SVG path={src} className={styles.svg} />
					<div className={styles.text}>{text}</div>
				</div>
			))}
		</div>
	)) ||
	null;

Icons.defaultProps = {
	icons: []
};

Icons.propTypes = {
	icons: PropTypes.arrayOf(
		PropTypes.shape({
			icon: PropTypes.shape({
				publicURL: PropTypes.string.isRequired
			}).isRequired,
			text: PropTypes.string.isRequired
		}).isRequired
	)
};

export default Icons;
